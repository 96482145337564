import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import { FaRegFilePdf } from 'react-icons/fa';
import { PDFDownloadLink } from '@react-pdf/renderer';

import PdfTemplate from '../components/PdfTemplate.js';
import domtoimage from 'dom-to-image';

class PrintSelect extends Component {
  constructor(props: Props) {
    super(props);
    this.pdfModalClose = this.pdfModalClose.bind(this);
  }

  componentDidMount() {
    this.printContainer = document.createElement('div');
    this.printContainer.setAttribute('id', 'printContainer');
  }

  pdfModalClose() {
    ReactDOM.unmountComponentAtNode(this.printContainer);
  }

  constructApiUrl() {
    //TODO evaluate for scalebar inclusion
    //var url = `https://api.mapbox.com/styles/v1/elwar/${this.props.mapStyle}/static/${this.props.lng},${this.props.lat},${this.props.zoom},0/800x550?access_token=${this.props.apiToken}`;
    //console.log(url);

    var node = document.getElementByClassName('mapbox-control-scale');
    var imgUrl;

    domtoimage
      .toPng(node)
      .then(function(dataUrl) {
        imgUrl = dataUrl;
      })
      .catch(function(error) {
        console.error('oops, something went wrong!', error);
      });

    return imgUrl;
  }

  handlePrint(key) {
    const App = () => (
      <div id="pdfModal">
        <Modal show="true" onHide={this.pdfModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>ELWar Mapdat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PDFDownloadLink
              document={
                <PdfTemplate
                  overlayTopic={this.props.overlayTopic.name}
                  overlayYear={this.props.overlayYear}
                  overlayTopicDesc={this.props.overlayTopic.desc}
                  year={this.props.year == 1940 ? '1941—1945' : this.props.year}
                  title={this.props.topic.name}
                  desc={this.props.topic.desc}
                  diffYear={this.props.diffYear}
                  apiUrl={this.props.canvasUrl}
                  colors={this.props.colors}
                  diffColors={this.props.diffColors}
                  stops={this.props.topic.stops}
                  diffStops={this.props.topic.diffStops}
                  overlayStops={this.props.overlayTopic.stops}
                  circles={this.props.circles}
                />
              }
              fileName="elwar-pdf_export.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download PDF'
              }
            </PDFDownloadLink>
          </Modal.Body>
        </Modal>
      </div>
    );

    ReactDOM.render(<App />, this.printContainer);
  }

  render() {
    var tp = this.props.overlayTopic.name ? '13.3rem' : '9.2rem';
    tp = this.props.circles ? '7rem' : tp;
    var drStyle = { position: 'absolute', top: tp, right: '7rem' };

    return (
      <div title="Eport PDF report">
        <DropdownButton
          alignRight
          title=<FaRegFilePdf />
          as={ButtonGroup}
          variant="outline-primary"
          id="pdf-dropdown"
          size="sm"
          style={drStyle}
        >
          <Dropdown.Item
            eventKey="2"
            onClick={() => this.handlePrint(2)}
            style={{ maxWidth: '170px' }}
          >
            Export the current map extent as PDF
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }
}

export default PrintSelect;
