import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import { FaCaretDown } from 'react-icons/fa';

export default class MapTooltip extends React.Component {
  static propTypes = {
    features: PropTypes.array.isRequired
  };

  //<span>{ topicId }</span> TODO: later -- put back to return, uncomment definition
  render() {
    const { features } = this.props;
    const renderFeature = (feature, i) => {
      // var topicId = (feature.properties[this.props.topicId] || feature.properties[this.props.topicId] === 0 ) ? feature.properties[this.props.topicId].toFixed(2) : "No data / Did not apply";
      return (
        <div key={i}>
          <strong>{feature.properties[this.props.titleCol]}</strong>
        </div>
      );
    };

    return (
      <div className="map-tooltip">
        <Badge
          variant="secondary"
          style={{ fontWeight: 'normal', fontFamily: 'Fira Sans' }}
        >
          <div>{features.slice(0, 1).map(renderFeature)}</div>
        </Badge>
        <FaCaretDown
          style={{ position: 'absolute', left: '46%', top: '60%' }}
        />
      </div>
    );
  }
}
