import React, { PureComponent } from 'react';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Slider from '@material-ui/core/Slider';

class TopicBar extends PureComponent {
  buildYearList() {
    const higlightYear =
      this.props.topic.segment === 'World War II' ? '1964' : this.props.year;
    const diffYear = this.props.diffYear;
    return (
      <Nav
        id="year-pills"
        className="mr-auto"
        variant="pills"
        activeKey={'#' + higlightYear}
      >
        {this.props.topic.range.map(year => (
          <Nav.Item
            key={year.toString()}
            style={{ width: '48px' }}
            active="true"
          >
            <Nav.Link
              onClick={() => this.yearTreat(year)}
              eventKey={'#' + year.toString()}
              active={year == higlightYear || year == diffYear ? true : false}
            >
              {this.props.topic.segment === 'WWII' && year === '1940'
                ? 'All'
                : year}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    );
  }

  yearTreat(year) {
    // in multihandle mode pick the nearest handle
    if (this.props.diffYear) {
      var years = [this.props.year, this.props.diffYear];
      var outYears;
      if (this.isBetween(year, years)) {
        outYears = this.findClosest(year, years);
      } else {
        if (year < years[0]) {
          outYears = [year, years[1]];
        } else {
          outYears = [years[0], year];
        }
      }
      this.props.handler(outYears);
    } else {
      this.props.handler(year);
    }
  }

  isBetween(val, pair) {
    if (val > pair[0] && val < pair[1]) {
      return true;
    }
    return false;
  }

  findClosest(val, pair) {
    const dist1 = Math.abs(val - pair[0]);
    const dist2 = Math.abs(val - pair[1]);
    if (dist1 <= dist2) {
      return [val, pair[1]];
    }
    return [pair[0], val];
  }

  getSliderBounds() {
    var slMin = 1970;
    var slMax = 1990;
    var slStep = 1;
    if (this.props.topic.segment === 'Census') {
      slMin = parseInt(this.props.topic.range[0]);
      slMax = parseInt(
        this.props.topic.range[this.props.topic.range.length - 1]
      );
      slStep = null;
    }
    if (this.props.topic.segment === 'WWII') {
      slMin = 1940;
      slMax = 1945;
      slStep = 1;
    }
    return [slMin, slMax, slStep];
  }

  buildYearSlider(slWidth, tWidth) {
    var sliderClass;
    if (this.props.overlay) {
      sliderClass = 'overlay-slider-nav';
    } else if (this.props.dual) {
      sliderClass = 'slider-nav-dual';
    } else {
      sliderClass = 'slider-nav';
    }
    var sliderMarginLeft = tWidth ? '450px' : '';
    var yearValue;
    if (this.props.diffYear) {
      yearValue = [this.props.year, this.props.diffYear];
    } else {
      yearValue = this.props.year;
    }
    var sliderBounds = this.getSliderBounds();

    return (
      <Navbar fixed="top" className={sliderClass} expand="lg">
        <Navbar.Collapse>
          {tWidth ? (
            ''
          ) : (
            <Navbar.Brand style={{ visibility: 'hidden', marginLeft: '3px' }}>
              <b>{this.props.topic.name}</b>
            </Navbar.Brand>
          )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Slider
            style={{
              width: slWidth.toString() + 'px',
              marginLeft: sliderMarginLeft
            }}
            defaultValue={yearValue}
            value={yearValue}
            aria-labelledby="discrete-slider"
            //valueLabelDisplay="auto"
            marks={
              this.props.topic.segment === 'Census'
                ? [{ value: 1971 }, { value: 1981 }, { value: 1991 }]
                : true
            }
            min={sliderBounds[0]}
            max={sliderBounds[1]}
            step={sliderBounds[2]}
            onChange={(e, val) => this.treatSliderYears(val)}
          />
        </Navbar.Collapse>
      </Navbar>
    );
  }

  treatSliderYears(val) {
    // dont select the same year with multihandle slider
    if (this.props.diffYear) {
      if (val[0] === val[1]) {
        if (val[0] === this.getSliderBounds()[0]) {
          val = [val[0], val[1] + 1];
        }
        val = [val[0] - 1, val[1]];
      }
    }
    this.props.handler(val);
  }

  render() {
    var slWidth = this.props.topic.range.length * 45.7;
    if (this.props.topic.segment === 'Census') {
      slWidth = this.props.topic.range.length * 32;
    }
    if (this.props.topic.segment === 'WWII') {
      slWidth = this.props.topic.range.length * 40;
    }
    var barId = this.props.overlay ? 'OverlayBar' : 'TopicBar';
    var tWidth = this.props.dual ? '410px' : '';
    return (
      <>
        <Navbar
          bg="white"
          fixed="top"
          className="shadow-sm"
          id={barId}
          expand={this.props.topic.freq === 'Annually' ? 'lg' : 'sm'}
        >
          <div style={{ minWidth: tWidth }}>
            {this.props.overlay ? (
              <Card.Subtitle style={{ color: '#007bff', marginRight: '8px' }}>
                Overlay: <b>{this.props.topic.name}</b>
                <br />
              </Card.Subtitle>
            ) : (
              <Navbar.Brand>
                <b>{this.props.topic.name}</b>
              </Navbar.Brand>
            )}
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {this.buildYearList(tWidth)}
          </Navbar.Collapse>
        </Navbar>
        {this.props.topic.segment === 'Economy' ||
        (this.props.topic.segment === 'Census' && this.props.diffYear) ||
        this.props.topic.segment === 'WWII'
          ? this.buildYearSlider(slWidth, tWidth)
          : ''}
      </>
    );
  }
}

export default TopicBar;
