import React, { PureComponent } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';

class OverlaySelect extends PureComponent {
  render() {
    var mathchingTopics = [];
    this.props.topics.forEach((topic, id) => {
      mathchingTopics.push([topic.id, topic.name, topic.segment]);
    });
    var title = `Select overlay layer`;
    var tp = this.props.overlayTopic ? '11rem' : '7rem';
    var drStyle = { position: 'absolute', top: tp, right: '7rem' };
    var clStyle = { position: 'absolute', top: tp, right: '3.5rem' };
    var segm;

    return (
      <>
        <DropdownButton
          title={title}
          variant="outline-primary"
          id="overlay-dropdown"
          key="test"
          size="sm"
          style={drStyle}
        >
          {mathchingTopics.map((tp, i) => {
            var switchSegm;
            if (segm === tp[2]) {
              switchSegm = false;
            } else {
              segm = tp[2];
              switchSegm = true;
            }
            return (
              <>
                {switchSegm ? (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Header> {segm} </Dropdown.Header>
                    <Dropdown.Divider />
                  </>
                ) : (
                  ''
                )}
                <Dropdown.Item
                  eventKey={i}
                  onClick={() => this.props.handler(tp[0])}
                >
                  {tp[1]}
                </Dropdown.Item>
              </>
            );
          })}
        </DropdownButton>
        {this.props.overlayTopic ? (
          <Button
            id="clear-button"
            variant="outline-primary"
            size="sm"
            style={clStyle}
            title="Remove the overlay theme form the map"
            onClick={() => this.props.handler('')}
          >
            Clear
          </Button>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default OverlaySelect;
