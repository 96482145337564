import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import MapNavbar from './components/MapNavbar.js';
import TopicList from './components/TopicList.js';
import WarTopicList from './components/WarTopicList.js';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/statistics">
            <div className="App">
              <MapNavbar />
              <TopicList />
            </div>
          </Route>
          <Route path="/war">
            <div className="App">
              <MapNavbar />
              <WarTopicList />
            </div>
          </Route>
        </Switch>
        <Redirect exact from="/" to="/statistics" />
      </BrowserRouter>
    );
  }
}

export default App;
