const topics = [
  {
    segment: 'WWII',
    id: 'wwii_all',
    disabled: 0,
    name: 'All types',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'Number',
    realm: 'type',
    note:
      'Total number of WWII dead per location. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'nov',
    disabled: 0,
    name: 'Partisan',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Partisan (Narodno-oslobodilacka vojska) deaths',
    realm: 'type',
    note:
      'Number of WWII dead partisans (Narodno-oslobodilacka vojska) per location. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'civ',
    disabled: 0,
    name: 'Civilian',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Civilian deaths',
    realm: 'type',
    note:
      'Number of WWII dead civilians per location. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'vkj',
    disabled: 0,
    name: 'Royal Army',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Royal Army (Vojska Kraljevine Jugoslavije) deaths',
    realm: 'type',
    note:
      'Number of WWII dead Royal Army (Vojska Kraljevine Jugoslavije) soldiers per location. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'camp',
    disabled: 0,
    name: 'Concentration Camp',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Concentration camp deaths',
    realm: 'type',
    note:
      'Number of WWII dead who died in concentration camps. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'wwii_all_eth',
    disabled: 0,
    name: 'All ethnicities',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII deaths',
    realm: 'ethnicity',
    note:
      'Total number of WWII dead per location. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'srb',
    disabled: 0,
    name: 'Serbs',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Serbian deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Serbian origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'cro',
    disabled: 0,
    name: 'Croats',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Croatian deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Croatian origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'mus',
    disabled: 0,
    name: 'Muslims',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Muslim deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Muslim origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'svn',
    disabled: 0,
    name: 'Slovenes',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Slovenian deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Slovenian origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'mne',
    disabled: 0,
    name: 'Montenegrins',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Montenegrin deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Montenegrin origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'mkd',
    disabled: 0,
    name: 'Macedonians',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Macedonian deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Macedonian origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'alb',
    disabled: 0,
    name: 'Albanians',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Albanian deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Albanian origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'hun',
    disabled: 0,
    name: 'Hungarians',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Hungarian deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Hungarian origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'rom',
    disabled: 0,
    name: 'Roma',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Roma deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Roma origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'WWII',
    id: 'jew',
    disabled: 0,
    name: 'Jews',
    stops: [10, 100, 500, 1000, 5000],
    desc: 'All WWII Jewish deaths',
    realm: 'ethnicity',
    note:
      'Number of WWII dead of Jewish origin. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  }
];

export default topics;
