import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapTooltip from '../components/MapTooltip.js';
import PrintSelect from '../components/PrintSelect.js';
// import FeatureAlert from '../components/FeatureAlert.js'

import mbconfig from '../enums/mbconfig.js';

mapboxgl.accessToken = mbconfig.token;

class BaseMap extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      lng: mbconfig.mapCenterLng,
      lat: mbconfig.mapCenterLat,
      zoom: mbconfig.mapZoom,
      selectedLayer: '',
      diffLayer: '',
      selectedOverlayLayer: '',
      featureClickedName: '',
      featureClickedValue: ''
    };
    var hoveredStateId = null;
    var clickedStateId = null;
  }

  componentDidMount() {
    const { lng, lat, zoom } = this.state;

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: mbconfig.style,
      center: [lng, lat],
      maxZoom: mbconfig.maxZoom,
      minZoom: mbconfig.minZoom,
      preserveDrawingBuffer: true,
      zoom
    });

    // disable map rotation using right click + drag
    this.map.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();

    const diffLayers = [
      'diff-Yuga_1985-from-1980',
      'diff-Yuga_1985-till-1980',
      'diff-Yuga_1986-from-1980',
      'diff-Yuga_1986-till-1980',
      'diff-Yuga_1987-from-1980',
      'diff-Yuga_1987-till-1980',
      'diff-Yuga_1988-from-1980',
      'diff-Yuga_1988-till-1980',
      'diff-Yuga_1989-from-1980',
      'diff-Yuga_1989-till-1980',
      'diff-Yuga_1990-from-1980',
      'diff-Yuga_1990-till-1980'
    ];

    this.map.on('move', () => {
      const { lng, lat } = this.map.getCenter();

      this.setState({
        lng: lng.toFixed(4),
        lat: lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2)
      });
    });

    this.map.on('load', () => {
      /*
      var myImage = new Image(20, 20);
      myImage.src = './assets/diagonal-stripe-1.svg.png';
      this.map.addImage('pattern', myImage);
      */

      // add base layers
      this.props.allYears.forEach(year => {
        this.map.addSource('Yuga_' + year, {
          type: 'vector',
          url: 'mapbox://elwar.Yuga_' + year
        });

        this.map.addLayer(
          {
            id: 'Yuga_' + year,
            type: 'fill',
            source: 'Yuga_' + year,
            'source-layer': 'Yuga_' + year,
            paint: {
              'fill-color': '#f77',
              'fill-opacity': 0.95
            }
          },
          'country_admin',
          'country labels',
          'vojvodina-border',
          'vojvodina-label'
        );

        // hide default layers
        this.map.setLayoutProperty('Yuga_' + year, 'visibility', 'none');
      });

      // add stripe layers
      this.props.allYears.forEach(year => {
        this.map.addLayer(
          {
            id: 'Yuga_' + year + '_stripes',
            type: 'fill',
            source: 'Yuga_' + year,
            'source-layer': 'Yuga_' + year,
            paint: {
              'fill-pattern': 'diagonal-stripe-1',
              'fill-opacity': 0.7
            }
          },
          'country_admin',
          'country labels',
          'vojvodina-border',
          'vojvodina-label'
        );

        // hide stripe layers
        this.map.setLayoutProperty(
          'Yuga_' + year + '_stripes',
          'visibility',
          'none'
        );
      });

      // add diff sources
      diffLayers.forEach(src => {
        this.map.addLayer(
          {
            id: src,
            type: 'fill',
            source: {
              type: 'vector',
              url: 'mapbox://elwar.' + src
            },
            'source-layer': src.split('-')[1],
            paint: {
              'fill-color': '#f77',
              'fill-opacity': 0.95
            }
          },
          'country_admin',
          'country labels',
          'vojvodina-border',
          'vojvodina-label'
        );

        // hide diff layers
        this.map.setLayoutProperty(src, 'visibility', 'none');
      });

      // add border layers
      this.props.allYears.forEach(year => {
        this.map.addLayer(
          {
            id: 'Yuga_' + year + '_line',
            type: 'line',
            source: 'Yuga_' + year,
            'source-layer': 'Yuga_' + year,
            paint: {
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#007bff',
                '#f1a8a5'
              ],
              'line-width': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                0.35
              ]
            }
          },
          'country_admin',
          'country labels',
          'vojvodina-border',
          'vojvodina-label'
        );

        // hide border layers
        this.map.setLayoutProperty(
          'Yuga_' + year + '_line',
          'visibility',
          'none'
        );
      });

      this.setVisible();
    });

    // add scale and zoom controls
    var scale = new mapboxgl.ScaleControl({
      position: 'bottom-right',
      maxWidth: 120,
      unit: 'metric'
    });
    this.map.addControl(scale);
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

    // add tooltip and feature border interactivity
    this.tooltipContainer = document.createElement('div');
    const tooltip = new mapboxgl.Marker(this.tooltipContainer, {
      offset: [0, -30]
    })
      .setLngLat([0, 0])
      .addTo(this.map);

    this.map.on('mousemove', e => {
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ['Yuga_' + this.props.year]
      });
      tooltip.setLngLat(e.lngLat);
      this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      this.setTooltip(features);

      // TODO use tippercanoe upload to workaround the multiple id selection glitch
      // https://blog.mapbox.com/going-live-with-electoral-maps-a-guide-to-feature-state-b520e91a22d
      //
      if (features.length > 0) {
        if (
          this.hoveredStateId &&
          this.hoveredStateId !== this.clickedStateId
        ) {
          this.map.setFeatureState(
            {
              source: 'Yuga_' + this.props.year,
              sourceLayer: 'Yuga_' + this.props.year,
              id: this.hoveredStateId
            },
            { hover: false }
          );
        }
        this.hoveredStateId = features[0].id;
        this.map.setFeatureState(
          {
            source: 'Yuga_' + this.props.year,
            sourceLayer: 'Yuga_' + this.props.year,
            id: this.hoveredStateId
          },
          { hover: true }
        );
      }
    });
  }

  componentDidUpdate() {
    if (this.map.isStyleLoaded()) {
      this.setVisible();
      this.hideLabels();
    }
  }

  componentWillUnmount() {
    this.map.remove();
  }

  getDiffLayer() {
    var extDiffYears = [1985, 1986, 1987, 1988, 1989, 1990];
    var currentDiffLayer;
    if (this.props.diffYear) {
      if (extDiffYears.includes(parseInt(this.props.diffYear))) {
        if (this.props.year < 1980) {
          currentDiffLayer = 'diff-Yuga_' + this.props.diffYear + '-till-1980';
        } else {
          currentDiffLayer = 'diff-Yuga_' + this.props.diffYear + '-from-1980';
        }
      } else {
        currentDiffLayer = 'Yuga_' + this.props.diffYear;
      }
    }
    return currentDiffLayer;
  }

  setVisible() {
    var currentLayer = 'Yuga_' + this.props.year;
    var currentDiffLayer = this.getDiffLayer();

    var currentLineLayer = 'Yuga_' + this.props.year + '_line';
    var currentDiffLineLayer = 'Yuga_' + this.props.diffYear + '_line';
    if (this.props.overlayYear) {
      var currentStripeLayer = 'Yuga_' + this.props.overlayYear + '_stripes';
    }
    // hide what was selected
    if (this.state.selectedLayer) {
      this.map.setLayoutProperty(
        this.state.selectedLayer,
        'visibility',
        'none'
      );
      this.map.setLayoutProperty(
        this.state.selectedLayer + '_line',
        'visibility',
        'none'
      );
    }
    if (this.state.diffLayer) {
      this.map.setLayoutProperty(this.state.diffLayer, 'visibility', 'none');
      /* TODO --  disable the right stripe layer, this misses out the first iteration
      this.map.setLayoutProperty((this.state.diffLayer.split('-')[1] || this.state.diffLayer) + 'line', 'visibility', 'none');
      */
    }

    // TODO these two need more efficiency
    this.props.allYears.forEach(year => {
      this.map.setLayoutProperty(
        'Yuga_' + year + '_stripes',
        'visibility',
        'none'
      );
    });
    this.props.allYears.forEach(year => {
      this.map.setLayoutProperty(
        'Yuga_' + year + '_line',
        'visibility',
        'none'
      );
    });

    // make visible with new settings
    if (this.props.overlayTopicId) {
      this.map.setLayoutProperty(currentStripeLayer, 'visibility', 'visible');
    }
    if (this.props.diffYear) {
      this.map.setLayoutProperty(currentDiffLayer, 'visibility', 'visible');
      this.map.setLayoutProperty(currentDiffLineLayer, 'visibility', 'visible');
    } else {
      this.map.setLayoutProperty(currentLayer, 'visibility', 'visible');
      this.map.setLayoutProperty(currentLineLayer, 'visibility', 'visible');
    }

    // preserve clicked feature on layer change
    /*
    if (this.clickedStateId) {
      this.map.setFeatureState(
        {
          source: currentLayer,
          sourceLayer: currentLayer,
          id: this.clickedStateId
        },
        { hover: true }
      )
    }
    */

    this.setState({
      selectedLayer: currentLayer,
      selectedOverlayLayer: currentStripeLayer,
      diffLayer: currentDiffLayer,
      featureClickedName: '',
      featureClickedValue: ''
    });

    // run set steps after layer is visible
    this.setSteps();

    // set outline higlight cease for current layer
    this.map.on('mouseleave', 'Yuga_' + this.props.year, e => {
      if (this.hoveredStateId && this.hoveredStateId !== this.clickedStateId) {
        this.map.setFeatureState(
          {
            source: 'Yuga_' + this.props.year,
            sourceLayer: 'Yuga_' + this.props.year,
            id: this.hoveredStateId
          },
          { hover: false }
        );
      }
      this.hoveredStateId = null;
    });

    // onclick style change
    this.map.on('click', 'Yuga_' + this.props.year, e => {
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ['Yuga_' + this.props.year]
      });

      if (features.length > 0) {
        this.setState({
          featureClickedName: features[0].properties['Općina'],
          featureClickedValue: features[0].properties[this.props.topicId]
        });
      }

      /*
      if (features.length > 0) {
        if (this.clickedStateId == features[0].id) {
          this.clickedStateId = null
        } else {
          this.map.setFeatureState(
            {
              source: 'Yuga_' + this.props.year,
              sourceLayer: 'Yuga_' + this.props.year,
              id: this.clickedStateId
            },
            { hover: false }
          )
          this.clickedStateId = features[0].id
        }
      }
      */
    });
  }

  setSteps() {
    var stops;
    var yr;
    var property;
    var layer;
    if (this.props.diffYear) {
      stops = this.props.topic.diffStops.map((e, i) => {
        return [e, this.props.diffColors[i]];
      });
      yr = this.props.diffYear;
      property = this.props.topicId + '_' + this.props.year;
      layer = this.getDiffLayer();
    } else {
      stops = this.props.topic.stops.map((e, i) => {
        return [e, this.props.colors[i]];
      });
      yr = this.props.year;
      property = this.props.topicId;
      layer = 'Yuga_' + yr;
    }

    this.map.setPaintProperty(layer, 'fill-color', {
      property,
      stops,
      default: 'lightgray'
    });

    if (this.props.overlayTopicId) {
      var patterns = [
        'dots',
        'diagonal-stripe-01',
        'diagonal-stripe-002',
        'diagonal-stripe-3',
        'diagonal-stripe-4'
      ];
      stops = this.props.overlayTopic.stops.map((e, i) => {
        return [e, patterns[i]];
      });
      property = this.props.overlayTopicId;

      this.map.setPaintProperty(
        'Yuga_' + this.props.overlayYear + '_stripes',
        'fill-pattern',
        {
          property,
          stops,
          default: 'dots'
        }
      );
    }
  }

  tooltipContainer;

  setTooltip(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(MapTooltip, {
          features: features,
          titleCol: 'Općina',
          topicId: this.props.topicId
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  getCanvas() {
    // output quality per device
    /*
    Object.defineProperty(window, 'devicePixelRatio', {
        get: function() {return 600 / 96} // 400 dpi
    });
    */
    var imgHref = this.map.getCanvas().toDataURL('image/png');
    return imgHref;
  }

  hideLabels() {
    if (this.props.overlayTopicId || this.props.diffYear) {
      this.map.setLayoutProperty('country labels', 'visibility', 'none');
      this.map.setLayoutProperty('vojvodina-label', 'visibility', 'none');
    } else {
      this.map.setLayoutProperty('country labels', 'visibility', 'visible');
      this.map.setLayoutProperty('vojvodina-label', 'visibility', 'visible');
    }
  }

  //causes trouble (rewrite)
  waitFor(conditionFunction) {
    const poll = resolve => {
      if (conditionFunction()) resolve();
      else setTimeout(() => poll(resolve), 400);
    };
    return new Promise(poll);
  }

  render() {
    var mtop = this.props.overlayTopic.desc ? '155px' : '95px';
    var hght = this.props.overlayTopic.desc ? '82%' : '90%';
    const style = {
      width: '100%',
      height: hght,
      position: 'absolute',
      marginTop: mtop
    };
    return (
      <div>
        <div style={style} ref={el => (this.mapContainer = el)} />
        <PrintSelect
          title={this.props.topic.name}
          topic={this.props.topic}
          overlayTopic={this.props.overlayTopic}
          diffYear={this.props.diffYear}
          year={this.props.year}
          overlayYear={this.props.overlayYear}
          apiToken={mbconfig.token}
          mapStyle={mbconfig.style.substring()}
          colors={this.props.colors}
          stops={this.props.stops}
          lat={this.state.lat}
          lng={this.state.lng}
          zoom={this.state.zoom}
          canvasUrl={() => this.getCanvas()}
          circles={0}
        />
      </div>
    );
  }
}

export default BaseMap;
