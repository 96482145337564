const topics = [
  {
    segment: 'Economy',
    id: 'ECON_natpw',
    name: 'National income per worker',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [100000, 200000, 300000, 500000, 900000],
    diffStops: [-100000, -50000, 50000, 100000, 200000],
    freq: 'Annually',
    desc: 'National income per worker, in 1980 dinars',
    note:
      'National income (narodni dohodak) per worker, in 1980 dinars. \nSource: Statistički godišnjak Jugoslavije, Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Economy',
    id: 'ECON_socpw',
    name: 'Social income per worker',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [100000, 150000, 250000, 350000, 600000],
    diffStops: [-100000, -50000, 50000, 100000, 200000],
    freq: 'Annually',
    desc:
      'Social sector income, per worker in the social sector, in 1980 dinars',
    note:
      'Social sector income (društveni dohodak) per worker in the social sector, in 1980 dinars. \nSource: Statistički godišnjak Jugoslavije, Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Economy',
    id: 'ECON_perspw',
    name: 'Personal income per worker',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [30000, 60000, 90000, 120000, 200000],
    diffStops: [-50000, -20000, 20000, 50000, 100000],
    freq: 'Annually',
    desc:
      'Personal income per worker (for 1970-1988 in the social sector, for 1989-1990 for social and private sector), in 1980 dinars',
    note:
      'Net personal income (čist lični dohodak - neto) per worker (for 1970-1988 in the social sector, for 1989-1990 for social and private sector), in 1980 dinars. \nSource: Statistički godišnjak Jugoslavije, Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Economy',
    id: 'ECON_invpw',
    name: 'Investments per worker',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [10000, 30000, 60000, 100000, 3000000],
    diffStops: [-100000, -50000, 50000, 100000, 200000],
    freq: 'Annually',
    desc:
      'Investments into social sector per worker in the social sector, in 1980 dinars',
    note:
      'Investments into social sector (ostvarene investicije u osnovna sredstva društvenog sektora) per worker in the social sector, in 1980 dinars. \nSource: Statistički godišnjak Jugoslavije, Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Economy',
    id: 'ECON_invpc',
    name: 'Investments per capita',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [1000, 5000, 10000, 100000, 4000000],
    diffStops: [-50000, -20000, 20000, 50000, 100000],
    freq: 'Annually',
    desc: 'Investments into social sector per capita, in 1980 dinars',
    note:
      'Investments (ostvarene investicije u osnovna sredstva društvenog sektora) into social sector per capita, in 1980 dinars. \nSource: Statistički godišnjak Jugoslavije, Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Economy',
    id: 'ECON_edhepc',
    name: 'Education and health spending per capita',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [1000, 2000, 5000, 25000, 4000000],
    diffStops: [-5000, -2000, 2000, 5000, 10000],
    freq: 'Annually',
    desc: 'Spending on education and health per capita, in 1980 dinars',
    note:
      'Spending (rashodi vanprivrednih djelatnosti) on education (obrazovanje) and health (zdravstvena zaštita) per capita, in 1980 dinars. \nSource: Statistički godišnjak Jugoslavije, Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Economy',
    id: 'ECON_priv',
    name: 'Private sector',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [1, 2, 5, 10, 100],
    diffStops: [-10, -5, 5, 10, 30],
    freq: 'Annually',
    desc: 'Proportion of workers who worked in the private sector',
    note:
      'Proportion of workers who worked in the private sector (sektor individualne svojine). \nSource: Statistički bilten Zaposlenost, Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Economy',
    id: 'ECON_adm',
    name: 'Administration',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [1, 2, 5, 10, 100],
    diffStops: [-7, -3, 3, 7, 20],
    freq: 'Annually',
    desc: 'Proportion of social sector workers who worked in administration',
    note:
      'Proportion of social sector workers who worked in administration (društveno političke zajednice i organizacije).j \nSource: Statistički bilten Zaposlenost, Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Economy',
    id: 'ECON_ind',
    name: 'Industry',
    range: [
      '1970',
      '1971',
      '1972',
      '1973',
      '1974',
      '1975',
      '1976',
      '1977',
      '1978',
      '1979',
      '1980',
      '1981',
      '1982',
      '1983',
      '1984',
      '1985',
      '1986',
      '1987',
      '1988',
      '1989',
      '1990'
    ],
    stops: [10, 30, 50, 70, 100],
    diffStops: [-10, -5, 5, 10, 50],
    freq: 'Annually',
    desc: 'Proportion of social sector workers who worked in industry',
    note:
      'Proportion of social sector workers who worked in industry. \nSource: Statistički bilten “Zaposlenost”. Belgrade: Savezni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_age',
    name: 'Average age',
    range: ['1971', '1981', '1991'],
    stops: [25, 30, 35, 40, 50],
    diffStops: [-10, -3, 3, 10, 30],
    freq: 'Census 3x',
    desc: 'Average age of municipal population.',
    note:
      'Average age of municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_act',
    name: 'Activity',
    range: ['1971', '1981', '1991'],
    stops: [15, 30, 45, 60, 80],
    diffStops: [-30, -5, 5, 30, 50],
    freq: 'Census 3x',
    desc: 'Proportion of population which is economically active.',
    note:
      'Proportion of population which is economically active. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_unem',
    name: 'Unemployment',
    range: ['1981', '1991'],
    stops: [5, 10, 15, 30, 40],
    diffStops: [-8, -3, 3, 8, 30],
    freq: 'Census 2x',
    desc: 'Rate of unemployment according to the census data',
    note:
      'Rate of unemployment according to the census data. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_agr',
    name: 'Agricultural population',
    range: ['1971', '1981', '1991'],
    stops: [15, 30, 45, 60, 90],
    diffStops: [-50, -5, 5, 50, 90],
    freq: 'Census 3x',
    desc:
      'Proportion of population relying on agricultural activity for a living',
    note:
      'Proportion of population relying on agricultural activity for a living. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_edu',
    name: 'Education',
    range: ['1971', '1981', '1991'],
    stops: [4, 6, 8, 10, 13],
    diffStops: [-3, -1, 1, 3, 6],
    freq: 'Census 3x',
    desc: 'Average years of education for population 15+',
    note:
      'Average years of education for population 15 years of age and older. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_illi',
    name: 'Illiteracy',
    range: ['1971', '1981', '1991'],
    stops: [5, 10, 15, 30, 50],
    diffStops: [-20, -5, 5, 20, 50],
    freq: 'Census 3x',
    desc: 'Rate of illiteracy for persons 10+',
    note:
      'Rate of illiteracy for persons 10 years of age and older. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_srb',
    name: 'Serbs',
    range: ['1971', '1981', '1991'],
    stops: [20, 40, 60, 80, 100],
    diffStops: [-20, -5, 5, 20, 50],
    freq: 'Census 3x',
    desc: 'Proportion of Serbs in the municipal population',
    note:
      'Proportion of Serbs in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_cro',
    name: 'Croats',
    range: ['1971', '1981', '1991'],
    stops: [20, 40, 60, 80, 100],
    diffStops: [-20, -5, 5, 20, 50],
    freq: 'Census 3x',
    desc: 'Proportion of Croats in the municipal population',
    note:
      'Proportion of Croats in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_mus',
    name: 'Muslims',
    range: ['1971', '1981', '1991'],
    stops: [20, 40, 60, 80, 100],
    diffStops: [-10, -5, 5, 10, 30],
    freq: 'Census 3x',
    desc: 'Proportion of Muslims in the municipal population',
    note:
      'Proportion of Muslims in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_svn',
    name: 'Slovenes',
    range: ['1971', '1981', '1991'],
    stops: [20, 40, 60, 80, 100],
    diffStops: [-10, -5, 5, 10, 30],
    freq: 'Census 3x',
    desc: 'Proportion of Slovenes in the municipal population',
    note:
      'Proportion of Slovenes in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_mne',
    name: 'Montenegrins',
    range: ['1971', '1981', '1991'],
    stops: [20, 40, 60, 80, 100],
    diffStops: [-10, -5, 5, 10, 30],
    freq: 'Census 3x',
    desc: 'Proportion of Montenegrins in the municipal population',
    note:
      'Proportion of Montenegrins in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_mac',
    name: 'Macedonians',
    range: ['1971', '1981', '1991'],
    stops: [20, 40, 60, 80, 100],
    diffStops: [-10, -5, 5, 10, 30],
    freq: 'Census 3x',
    desc: 'Proportion of Macedonians in the municipal population',
    note:
      'Proportion of Macedonians in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_yug',
    name: 'Yugoslavs',
    range: ['1971', '1981', '1991'],
    stops: [2, 4, 7, 12, 40],
    diffStops: [-10, -5, 5, 10, 30],
    freq: 'Census 3x',
    desc: 'Proportion of Yugoslavs in the municipal population',
    note:
      'Proportion of Yugoslavs in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_ndc',
    name: 'Nationally non-declared',
    range: ['1971', '1981', '1991'],
    stops: [0.5, 1, 2, 3, 5],
    diffStops: [-2, -0.5, 0.5, 2, 5],
    freq: 'Census 3x',
    desc: 'Proportion of nationally non-declared in the municipal population',
    note:
      'Proportion of nationally non-declared in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_alb',
    name: 'Albanians',
    range: ['1971', '1981', '1991'],
    stops: [20, 40, 60, 80, 100],
    diffStops: [-10, -5, 5, 10, 30],
    freq: 'Census 3x',
    desc: 'Proportion of Albanians in the municipal population',
    note:
      'Proportion of Albanians in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku'
  },
  {
    segment: 'Census',
    id: 'CENS_hun',
    name: 'Hungarians',
    range: ['1971', '1981', '1991'],
    stops: [10, 40, 60, 80, 100],
    diffStops: [-10, -5, 5, 10, 30],
    freq: 'Census 3x',
    desc: 'Proportion of Hungarians in the municipal population',
    note:
      'Proportion of Hungarians in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_rom',
    name: 'Roma',
    range: ['1971', '1981', '1991'],
    stops: [0.5, 1, 5, 15, 40],
    diffStops: [-3, -1, 1, 3, 8],
    freq: 'Census 3x',
    desc: 'Proportion of Roma in the municipal population',
    note:
      'Proportion of Roma in the municipal population. Sources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_tur',
    name: 'Turks',
    range: ['1971', '1981', '1991'],
    stops: [0.5, 1, 5, 15, 20],
    diffStops: [-5, -1, 1, 5, 8],
    freq: 'Census 3x',
    desc: 'Proportion of Turks in the municipal population',
    note:
      'Proportion of Turks in the municipal population. \nSources: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_efi',
    name: 'EFI',
    range: ['1971', '1981', '1991'],
    stops: [0.2, 0.4, 0.6, 0.8, 1],
    diffStops: [-0.3, -0.1, 0.1, 0.3, 0.8],
    freq: 'Census 3x',
    desc: 'Ethnic fractionalisation index',
    note:
      'Ethnic fractionalisation index, defined as EFI = 1 - (Σᵢ₌₁ᴺ × πᵢ²) where πᵢ is the share of group i (i=1…N) in a given municipality. \nCalculations based on: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Census',
    id: 'CENS_epi',
    name: 'EPI',
    range: ['1971', '1981', '1991'],
    stops: [0.2, 0.4, 0.6, 0.8, 1],
    diffStops: [-0.3, -0.1, 0.1, 0.3, 0.8],
    freq: 'Census 3x',
    desc: 'Ethnic polarisation index',
    note:
      'Ethnic polarisation index, defined as EPI = 1 - (Σᵢ₌₁ᴺ × ((1/2 - πᵢ) / (1/2))² × πᵢ) where πᵢ is the share of group i (i=1…N) in a given municipality. \nCalculations based on: Relevant census publications. Belgrade: Savezni zavod za statistiku; Ljubljana: Statistični urad RS; Sarajevo: Republički zavod za statistiku; Skopje: Republički zavod za statistika; Zagreb: Državni zavod za statistiku.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_turnout',
    name: 'Turnout',
    range: ['1990'],
    stops: [60, 70, 80, 90, 100],
    freq: 'Election 1x',
    desc: 'Turnout in the 1990 election',
    note:
      'Turnout in the 1990 elections. Sources: Electoral commissions of all Yugoslav republics.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_skplus',
    name: 'Leagues of Communists (SK)',
    range: ['1990'],
    stops: [20, 40, 60, 80, 100],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to SK and their allies',
    note:
      'Proportion of votes in 1990 election given to the various republican Leagues of Communists (Savez komunista – SK) or its successor parties and their satellites (Bosnia and Herzegovina: SKBiH, DSS, SSO-DS; Croatia: SKH-SDP, SS-SSH, SSOH; Macedonia: SKM-PDP, SPM; Montenegro: SKCG, SSCG; Serbia: SPS; Slovenia: ZKS, ZSMS, SZS-SZDL, ZZB NOV). Sources: Electoral commissions of all Yugoslav republics.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_srsj',
    name: 'Reformists (SRSJ)',
    range: ['1990'],
    stops: [5, 10, 15, 30, 60],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to SRSJ',
    note:
      'Proportion of votes in 1990 election given to the League of Reformist Forces of Yugoslavia (Savez reformskih snaga Jugoslavije – SRSJ) which contested elections in Bosnia and Herzegovina, Macedonia, Montenegro, and Serbia. Sources: Electoral commissions of Bosnia and Herzegovina, Macedonia, Montenegro, and Serbia.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_hdz',
    name: 'Croatian Democratic Union (HDZ)',
    range: ['1990'],
    stops: [20, 40, 60, 80, 100],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to HDZ',
    note:
      'Proportion of votes in 1990 election given to the Croatian Democratic Union (Hrvatska demokratska zajednica – HDZ) which contested elections in Bosnia and Herzegovina and Croatia. Sources: Electoral commissions of Bosnia and Herzegovina and Croatia.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_sds',
    name: 'Serb Democratic Party (SDS)',
    range: ['1990'],
    stops: [20, 40, 60, 80, 100],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to SDS',
    note:
      'Proportion of votes in 1990 election given to the Serb Democratic Party (Srpska demokratska stranka – SDS) which contested elections in Bosnia and Herzegovina, Croatia, and Serbia. Sources: Electoral commissions of Bosnia and Herzegovina, Croatia, and Serbia.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_sda',
    name: 'Party of Democratic Action (SDA)',
    range: ['1990'],
    stops: [20, 40, 60, 80, 100],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to SDA',
    note:
      'Proportion of votes in 1990 election given to the Party of Democratic Action (Stranka demokratske akcije – SDA) which contested elections in Bosnia and Herzegovina and Serbia. Sources: Electoral commissions of Bosnia and Herzegovina and Serbia.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_demos',
    name: 'Democratic Opposition (Demos)',
    range: ['1990'],
    stops: [30, 40, 50, 70, 100],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to Demos',
    note:
      'Proportion of votes in 1990 election given to the Democratic Opposition of Slovenia (Demokratična opozicija Slovenije – Demos) which contested elections in Slovenia. \nSource: Electoral commission of Slovenia.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_kns',
    name: "Coalition of People's Accord (KNS)",
    range: ['1990'],
    stops: [5, 10, 15, 30, 60],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to KNS',
    note:
      'Proportion of votes in 1990 election given to the Coalition of People’s Accord (Koalicija narodnog sporazuma – KNS) which contested elections in Croatia. \nSource: Electoral commission of Croatia.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_vmro',
    name: 'VMRO-DPMNE',
    range: ['1990'],
    stops: [5, 10, 15, 20, 60],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to VMRO-DPMNE',
    note:
      'Proportion of votes in 1990 election given to the Internal Macedonian Revolutionary Organization – Democratic Party for Macedonian National Unity (Внатрешна македонска револуционерна организација – Демократска партија за македонско национално единство – VMRO-DPMNE) which contested elections in Macedonia. \nSource: Electoral commission of Macedonia.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_spo',
    name: 'Serb Renewal Movement (SPO)',
    range: ['1990'],
    stops: [5, 10, 15, 20, 60],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to SPO',
    note:
      'Proportion of votes in 1990 election given to the Serb Renewal Movement (Srpski pokret obnove – SPO) which contested elections in Serbia. \nSource: Electoral commission of Serbia.'
  },
  {
    segment: 'Elections',
    id: 'ELEC_ds',
    name: 'Democratic Party (DS)',
    range: ['1990'],
    stops: [5, 10, 15, 20, 60],
    freq: 'Election 1x',
    desc: 'Proportion of votes in 1990 election given to DS',
    note:
      'Proportion of votes in 1990 election given to the Democratic Party (Demokratska stranka – DS) which contested elections in Serbia. \nSource: Electoral commission of Serbia.'
  },
  {
    segment: 'World War II',
    id: 'WAR_dead',
    name: 'Total deaths',
    range: ['1964'],
    stops: [10, 20, 30, 60, 400],
    freq: 'Census 1x',
    desc:
      'Number of WWII dead per 1000 inhabitants of the municipality of the reporting member of family',
    note:
      'Number of WWII dead per 1000 inhabitants of the municipality of the reporting member of family, according to the 1964 census of WWII victims. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'World War II',
    id: 'WAR_deadpartis',
    name: 'Partisan deaths',
    range: ['1964'],
    stops: [5, 10, 20, 40, 200],
    freq: 'Census 1x',
    desc:
      'Number of WWII dead resistance fighters per 1000 inhabitants of the municipality of the reporting member of family',
    note:
      'Number of WWII dead resistance fighters per 1000 inhabitants of the municipality of the reporting, according to the 1964 census of WWII victims member of family. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'World War II',
    id: 'WAR_deadcivil',
    name: 'Civilian deaths',
    range: ['1964'],
    stops: [10, 20, 30, 60, 400],
    freq: 'Census 1x',
    desc:
      'Number of WWII dead civilians per 1000 inhabitants of the municipality of the reporting member of family',
    note:
      'Number of WWII dead civilians per 1000 inhabitants of the municipality of the reporting member of family, according to the 1964 census of WWII victims. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'World War II',
    id: 'WAR_deadroyal',
    name: 'Royal Army deaths',
    range: ['1964'],
    stops: [0.5, 1, 1.5, 2, 3],
    freq: 'Census 1x',
    desc:
      'Number of WWII dead Royal Army soldiers per 1000 inhabitants of the municipality of the reporting member of family',
    note:
      'Number of WWII dead Royal Army soldiers per 1000 inhabitants of the municipality of the reporting member of family, according to the 1964 census of WWII victims. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'World War II',
    id: 'WAR_surv',
    name: 'Surviving victims',
    range: ['1964'],
    stops: [10, 20, 30, 60, 500],
    freq: 'Census 1x',
    desc:
      'Number of people who survived various WWII crimes by Axis forces per 1000 inhabitants of the municipality of the reporting person',
    note:
      'Number of people who survived various WWII crimes by Axis forces per 1000 inhabitants of the municipality of the reporting person, according to the 1964 census of WWII victims. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  },
  {
    segment: 'World War II',
    id: 'WAR_survroyal',
    name: 'Surviving Royal Army victims',
    range: ['1964'],
    stops: [5, 10, 15, 30, 70],
    freq: 'Census 1x',
    desc:
      'Number of Royal Army soldiers who survived various WWII crimes by Axis forces per 1000 inhabitants of the municipality of the reporting person',
    note:
      'Number of Royal Army soldiers who survived various WWII crimes by Axis forces per 1000 inhabitants of the municipality of the reporting person, according to the 1964 census of WWII victims. \nSource: Žrtve rata 1941-1945. (rezultati popisa), Belgrade: Savezni zavod za statistiku, 1966.'
  }
];

export default topics;
