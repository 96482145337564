import React from 'react';
import moment from 'moment';
import {
  Page,
  Font,
  Image,
  View,
  Document,
  StyleSheet
} from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const PdfTemplate = function PdfTemplate(props) {
  // Create styles

  Font.register({
    family: 'Fira Sans',
    src: 'https://fonts.gstatic.com/s/firasans/v10/va9E4kDNxMZdWfMOD5Vvl4jO.ttf'
  });

  const Heading = styled.Text`
    margin-top: 12px;
    font-size: 15px;
    font-weight: bolder;
    font-family: Fira Sans;
  `;

  const Timestamp = styled.Text`
    font-size: 10px;
    color: darkgray;
    font-family: Fira Sans;
    position: relative;
    float: right;
  `;

  const Smalltext = styled.Text`
    font-size: 5px;
    color: black;
    font-family: Fira Sans;
    width: 20%;
  `;

  const styles = StyleSheet.create({
    page: {
      backgroundColor: 'white'
    },
    section: {
      margin: 10,
      padding: 10,
      width: '100%'
    },
    row: {
      flex: 1,
      flexDirection: 'row',
      flexGrow: 1
    },
    left: {
      width: '76%'
    },

    right: {
      padding: 5,
      width: '23%'
    }
  });

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Heading>
            {props.title}{' '}
            {props.diffYear ? `${props.year}—${props.diffYear}` : props.year}
          </Heading>
          <Timestamp>
            {props.desc}{' '}
            {props.diffYear
              ? `— difference between years ${props.year} and ${props.diffYear}`
              : ''}
          </Timestamp>
          {props.diffYear ? (
            <>
              <Image
                src="./assets/diffLegend.png"
                style={{ width: '150px', padding: '2px' }}
              />
              <View style={{ ...styles.row, marginLeft: '5%', width: '150px' }}>
                {props.diffStops.map((e, i) =>
                  props.diffStops[i] !== props.diffStops.slice(-1)[0] ? (
                    <Smalltext>{e}</Smalltext>
                  ) : (
                    <br />
                  )
                )}
              </View>
            </>
          ) : (
            <>
              <Image
                src={
                  props.circles
                    ? './assets/legendCircles.png'
                    : './assets/legendRegular.png'
                }
                style={
                  props.circles
                    ? { width: '90px', padding: '2px', marginTop: '6px' }
                    : { width: '150px', padding: '2px' }
                }
              />
              <View style={{ ...styles.row, marginLeft: '5%', width: '150px' }}>
                {props.stops.map((e, i) =>
                  props.stops[i] !== props.stops.slice(-1)[0] ? (
                    <Smalltext>{e}</Smalltext>
                  ) : (
                    <br />
                  )
                )}
              </View>
            </>
          )}
          <Heading style={{ color: '#007bff' }}>
            {props.overlayTopic} {props.overlayTopic ? props.overlayYear : ''}
          </Heading>
          <Timestamp>{props.overlayTopicDesc}</Timestamp>
          {props.overlayTopicDesc ? (
            <>
              <Image
                src="./assets/overlayLegend.png"
                style={{ width: '150px', padding: '2px' }}
              />
              <View style={{ ...styles.row, marginLeft: '5%', width: '150px' }}>
                {props.stops.map((e, i) =>
                  props.stops[i] !== props.stops.slice(-1)[0] ? (
                    <Smalltext>{e}</Smalltext>
                  ) : (
                    <br />
                  )
                )}
              </View>
            </>
          ) : (
            <br />
          )}
        </View>
        <Image src={props.apiUrl} style={{ width: '100%' }} />

        <View style={styles.row}>
          <View style={{ ...styles.section, ...styles.left, marginRight: 0 }}>
            <Timestamp>
              Generated from <a href="https://mapdat.uni.lu">mapdat.uni.lu</a>
            </Timestamp>
            <Timestamp>on {moment().format('MMMM Do YYYY')}</Timestamp>
          </View>
          <View style={styles.right}>
            <View style={styles.row}>
              <Image
                src="./assets/uni-logo.png"
                style={{ height: '25px', padding: '2px' }}
              />
              <Image
                src="./assets/erc-logo.jpg"
                style={{ height: '25px', padding: '2px' }}
              />
              <Image
                src="./assets/eu-flag.jpg"
                style={{ height: '25px', padding: '2px' }}
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return <MyDocument />;
};
export default PdfTemplate;
