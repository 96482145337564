import React from 'react';
import Modal from 'react-bootstrap/Modal';

const InfoModal = function InfoModal(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ELWar Mapdat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Mapdat is the online platform of the project ELWar (Electoral
            Legacies of War) which is funded by the ERC and is hosted by the
            Institute of Political Science at the University of Luxembourg. The
            platform organises collected publicly available data in geographic
            space and over time. The primary purpose of the platform is to
            visually present the evolution of economic, social, and political
            development in Southeast Europe over the past five decades with
            special reference to the legacies and effects of war violence.
          </p>
          <p>
            The platform is a work in progress which will continue to be
            populated with new data and new functional capabilities by the end
            of the project in 2022 when the full datasets will also be released.
            Users are encouraged to send suggestions for improvements to{' '}
            <a href="mailto:elwar@uni.lu">elwar@uni.lu</a>.
          </p>
          <p>
            <i>Authors:</i> Josip Glaurdić, Tomislav Kaniški, Christophe
            Lesschaeve, Michal Mochtak, Peter Ondrejka
          </p>
          <p>
            <i>Research Assistance:</i> Rafaëlle de La Tullaye, Leo Fel, Damir
            Galić, Ljubica Glaurdić, Daniela Janeva, Nina-Carolin Krumnau
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InfoModal;
