import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

import InfoModal from '../components/InfoModal.js';

class MapNavbar extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalShow: false
    };
  }

  /* removed from render
        <Nav className="mr-auto">
          <NavDropdown title="Yugoslavia" id="basic-nav-dropdown">
          </NavDropdown>
        </Nav>
  */

  showInfoModal() {
    this.setState({ modalShow: true });
  }

  hideInfoModal() {
    this.setState({ modalShow: false });
  }

  render() {
    var location = window.location.pathname;
    return (
      <>
        <Navbar
          bg="secondary"
          variant="dark"
          fixed="top"
          className="shadow-sm"
          style={{ height: '2.2rem' }}
        >
          <Navbar.Brand href="https://elwar.uni.lu/">
            <img
              src="./assets/elwar-logo.jpg"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Logo of the project"
            />
          </Navbar.Brand>
          <Navbar.Brand href="">ELWar Mapdat</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Nav>
            <Nav.Link
              as={Link}
              className="statistics"
              active={location === '/statistics' ? true : false}
              to="/statistics"
            >
              Yugoslavia (1970—1991)
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="war"
              active={location === '/war' ? true : false}
              to="/war"
            >
              War Deaths (1941—1945)
            </Nav.Link>
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <Button variant="secondary" onClick={() => this.showInfoModal()}>
              {' '}
              Impressum{' '}
            </Button>
            <Navbar.Text>v.1 Beta</Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <InfoModal
          show={this.state.modalShow}
          handleClose={() => this.hideInfoModal()}
        />
      </>
    );
  }
}

export default MapNavbar;
