const mbconfig = {
  token:
    'pk.eyJ1IjoiZWx3YXIiLCJhIjoiY2p0OXYzZHJxMDRidTQ1bzg3c2Q5eDRxdiJ9.GnrEQZm8bjzJMBstoyqc-A',
  style: 'mapbox://styles/elwar/ck73nqveb2d901ipmn84nxpkz',
  mapCenterLat: 43.6,
  mapCenterLng: 17.7141,
  mapZoom: 5.8,
  maxZoom: 19,
  minZoom: 5.3
};

/*
//  priv token: 'sk.eyJ1IjoiZWx3YXIiLCJhIjoiY2s1d3Z6bG50MDZycTNscGRxdTRyb3NiZiJ9.QW-jwModPLARvICezPtZJQ'
//  ppeto instance token: sk.eyJ1IjoicHBlZXR0b28iLCJhIjoiY2p6MzVjbmc0MDVkMTNobzFxbWZhODB2MCJ9.ZR8VQVyVpJqKq90S-TThmA 
const mbconfig_dev = { 
  token: 'pk.eyJ1IjoicHBlZXR0b28iLCJhIjoiY2loN21nMTBuMHQ1YXVta2l6YjJzbHM4YSJ9.cpjhFrPRqzOF037dktUgBw',
  style: 'mapbox://styles/ppeettoo/cjshkzucs505f1fpo04uf2zpw',
  mapCenterLat: 43.669000,
  mapCenterLng: 17.714100,
  mapZoom: 5.3,
};
const mbconfig = mbconfig_dev;
*/

export default mbconfig;
