const allCombos = [
  ['civ', 'srb'],
  ['camp', 'civ', 'svn'],
  ['cro', 'nov'],
  ['camp', 'civ', 'srb'],
  ['nov', 'srb'],
  ['srb', 'vkj'],
  ['camp', 'civ', 'mus'],
  ['mne', 'nov'],
  ['mus', 'nov'],
  ['civ', 'mus'],
  ['camp', 'civ', 'jew'],
  ['camp', 'civ', 'cro'],
  ['civ', 'cro'],
  ['camp', 'civ', 'mne'],
  ['civ', 'jew'],
  ['civ', 'svn'],
  ['camp', 'civ'],
  ['mne', 'vkj'],
  ['civ', 'mkd'],
  ['civ', 'hun'],
  ['camp', 'civ', 'hun'],
  ['camp', 'civ', 'mkd'],
  ['jew', 'nov'],
  ['nov', 'svn'],
  ['cro', 'vkj'],
  ['mus', 'vkj'],
  ['civ', 'mne'],
  ['hun', 'nov'],
  ['mkd', 'vkj'],
  ['civ', 'rom'],
  ['camp', 'civ', 'rom'],
  ['nov', 'rom'],
  ['alb', 'civ'],
  ['mkd', 'nov'],
  ['alb', 'vkj'],
  ['alb', 'nov'],
  ['svn', 'vkj'],
  ['jew', 'vkj']
];

export default allCombos;
