import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { FaCaretDown } from 'react-icons/fa';
import { GoInfo } from 'react-icons/go';

import topics from '../enums/themes-war.js';
import allCombos from '../enums/themes-war-combos.js';

import TopicTitle from '../components/TopicTitle.js';
import TopicBar from '../components/TopicBar.js';
import MapDeaths from '../components/WarMap.js';

import * as R from 'ramda';

class TopicList extends Component {
  constructor(props: Props) {
    super(props);
    // initial topic
    const initId = {
      type: 'wwii_all',
      ethnicity: 'wwii_all_eth'
    };
    const initObjects = topics.filter(x =>
      Object.values(initId).includes(x.id)
    );
    this.handleYear = this.handleYear.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      selectedTopicId: initId,
      selectedYear: '1940', //alias for all
      topics: topics,
      selectedTopicObjects: initObjects,
      warBarObject: {
        segment: 'WWII',
        id: 'WWII',
        name: 'WWII Deaths',
        range: ['1940', '1941', '1942', '1943', '1944', '1945'],
        stops: [10, 500, 1000, 0]
      },
      activeCombos: [],
      colors: ['#f77']
    };
  }

  componentDidMount() {
    this.handleSelect(this.state.selectedTopicId['type'], 'type');
    this.handleSelect(this.state.selectedTopicId['ethnicity'], 'ethnicity');
  }

  componentDidUpdate() {}

  buildList(topics) {
    var typeEntries = {
      type: [],
      ethnicity: []
    };

    // get height
    var body = document.body;
    var html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    height = height / 2;
    topics.forEach((top, i) => {
      typeEntries[top.realm].push(this.addRadio(top, i));
    });
    return (
      <Accordion
        defaultActiveKey="0"
        id="TopicListType"
        style={{ marginTop: '0.5rem' }}
      >
        <Card className="shadow-sm" key={'listitem-select-type'}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={'0'}
            title="Select by type and ethnicity"
          >
            Select by type and ethnicity
            <FaCaretDown
              style={{
                position: 'absolute',
                right: '5%',
                color: 'lightgray'
              }}
            />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={'0'}>
            <div style={{ maxHeight: height, overflowY: 'scroll' }}>
              <Card>
                <Card.Body>
                  <Form>{typeEntries['type']}</Form>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Form>{typeEntries['ethnicity']}</Form>
                </Card.Body>
              </Card>
            </div>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  addRadio(object, count) {
    const obj = object;
    const i = count + 1;
    var objIds = Object.values(this.state.selectedTopicId);

    return (
      <div
        key={'radio-' + obj.realm + '-' + i.toString()}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Form.Check
          id={i}
          type="radio"
          custom
          label={obj.name}
          style={{ float: 'left' }}
          title={obj.note}
          checked={objIds.includes(obj.id)}
          onChange={this.handleSelect.bind(this, obj.id, obj.realm)}
          name="topics"
        />
        <Button
          title={obj.note}
          variant="outline-light"
          size="sm"
          style={{ marginRight: '-8px' }}
        >
          <GoInfo style={{ float: 'right', color: 'lightgray' }} />
        </Button>
      </div>
    );
  }

  getAvailableCombinations(selectedIds) {
    var ids = R.without(['wwii_all', 'wwii_all_eth'], selectedIds);
    if (ids.length == 0) {
      return [['wwii_all']];
    } else {
      let filters = ids.map(id => {
        return R.filter(R.includes(id));
      });
      let transform = R.compose(...filters);
      // transduce makes combined filters iterate just once
      return R.transduce(transform, R.flip(R.append), [], allCombos);
    }
  }

  handleSelect(objId, realmKey) {
    var year = this.state.selectedYear;
    var newSelectedTopicId = this.state.selectedTopicId;
    newSelectedTopicId[realmKey] = objId;

    var updatedObjIds = Object.values(newSelectedTopicId);

    // cache combo calculation results
    const memoGetAvailableCombinations = R.memoizeWith(R.identity, n =>
      this.getAvailableCombinations(n)
    );
    var activeCombos = memoGetAvailableCombinations(updatedObjIds);

    // combos to field names
    var combos = [];
    activeCombos.forEach(e => {
      combos.push(e.join());
    });
    var selObjects = this.state.topics.filter(x =>
      Object.values(newSelectedTopicId).includes(x.id)
    );
    this.setState({
      selectedTopicId: newSelectedTopicId,
      selectedTopicObjects: selObjects,
      selectedYear: year,
      activeCombos: combos
    });
  }

  handleYear(yr) {
    this.setState({
      selectedYear: yr
    });
  }

  render() {
    return (
      <div>
        <MapDeaths
          genericTopic={this.state.warBarObject}
          selectedTopics={this.state.selectedTopicObjects}
          year={this.state.selectedYear}
          combos={this.state.activeCombos}
        />
        <TopicBar
          topic={this.state.warBarObject}
          year={this.state.selectedYear}
          handler={this.handleYear}
          overlay={false}
        />
        <div className="panel" style={{ marginTop: '6.5rem' }}>
          <TopicTitle
            colors={this.state.colors}
            topic={this.state.selectedTopicObjects}
            year={this.state.selectedYear}
            diffColors={''}
            overlayTopic={''}
            diffYear={''}
          />
          {this.buildList(this.state.topics)}
        </div>
        <div
          className="mapboxgl-ctrl-bottom-right"
          style={{
            marginBottom: '30px',
            marginRight: '10px',
            align: 'right',
            zIndex: '10000',
            pointerEvents: 'auto'
          }}
        >
          <a href="https://wwwen.uni.lu/">
            <Image src="./assets/uni-logo.png" width="40" height="40" />
          </a>
          <br />
          <Image src="./assets/erc-logo.jpg" width="40" height="40" />
          <br />
          <Image src="./assets/eu-flag.jpg" width="40" height="25" />
        </div>
      </div>
    );
  }
}

export default TopicList;
