import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { FaCaretDown } from 'react-icons/fa';
import { GoInfo } from 'react-icons/go';

import topics from '../enums/themes-yg.js';
import segments from '../enums/segments.js';

import BaseMap from '../components/Map.js';
import TopicTitle from '../components/TopicTitle.js';
import TopicBar from '../components/TopicBar.js';
import OverlaySelect from '../components/OverlaySelect';

class TopicList extends Component {
  constructor(props: Props) {
    super(props);
    const initId = 'ECON_natpw'; // initial topic
    this.handleYear = this.handleYear.bind(this);
    this.handleOverlayYear = this.handleOverlayYear.bind(this);
    this.handleOverlay = this.handleOverlay.bind(this);
    this.enableDiff = this.enableDiff.bind(this);
    this.state = {
      selectedTopicId: initId,
      selectedYear: '1970',
      topics: [],
      selectedTopicObject: topics.find(x => x.id === initId),
      allYears: [],
      overlayYear: '1970',
      overlayTopicId: '',
      overlayTopicObject: [],
      dualSliders: false,
      diffYear: '',
      // orange-red
      //colors: [ '#fef0d9', '#fdcc8a', '#fc8d59', '#e34a33', '#b30000' ],
      // pink-violet
      //colors: ['#f8d5cc', '#f1a8a5', '#ec739b', '#c44cc0', '#6e40e6'],
      // yellow-red
      //colors: [ '#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026']
      // reddish
      //colors: [ '#fee5d9', '#fcae91', '#fb6a4a', '#de2d26', '#a50f15']
      // red more dense
      // colors: [ '#fff5f0', '#fcbba1', '#fb6a4a', '#de2d26', '#cb181d']
      colors: ['#fff5f0', '#fee0d2', '#fc9272', '#de2d26', '#cb181d'],
      diffColors: ['#2166ac', '#92c5de', '#f7f7f7', '#f4a582', '#b2182b']
    };
  }

  componentDidMount() {
    this.setState({
      topics: topics,
      allYears: [
        '1970',
        '1971',
        '1972',
        '1973',
        '1974',
        '1975',
        '1976',
        '1977',
        '1978',
        '1979',
        '1980',
        '1981',
        '1982',
        '1983',
        '1984',
        '1985',
        '1986',
        '1987',
        '1988',
        '1989',
        '1990',
        '1991'
      ]
    });
  }

  componentDidUpdate() {}

  buildList(segments, topics) {
    const listItems = segments.map((seg, i) => (
      <Card className="shadow-sm" key={'listitem-' + i.toString()}>
        <Accordion.Toggle as={Card.Header} eventKey={i.toString()}>
          {seg}
          <FaCaretDown
            style={{ position: 'absolute', right: '5%', color: 'lightgray' }}
          />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={i.toString()}>
          <Card.Body>
            {topics.map((top, i) =>
              top.segment === seg ? this.addRadio(top, i) : null
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    ));
    return listItems;
  }

  addRadio(object, count) {
    const obj = object;
    const i = count + 1;
    return (
      <div
        key={'radio-' + i.toString()}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Form.Check
          id={i}
          type="radio"
          custom
          label={obj.name}
          style={{ float: 'left' }}
          title={obj.note}
          checked={obj.id === this.state.selectedTopicId ? true : false}
          onChange={this.handleSelect.bind(this, obj.id)}
          name="topics"
        />
        <Button
          title={obj.note}
          variant="outline-light"
          size="sm"
          style={{ marginRight: '-8px' }}
        >
          <GoInfo style={{ float: 'right', color: 'lightgray' }} />
        </Button>
      </div>
    );
  }

  handleSelect(objId) {
    var selObject = this.state.topics.find(x => x.id === objId);
    var year;
    if (selObject.range.includes(this.state.selectedYear.toString())) {
      year = this.state.selectedYear;
      // eslint-disable-next-line
    } else if (selObject.range[0] == 1964) {
      year = 1970;
    } else {
      year = selObject.range[0];
    }
    var singleYear =
      selObject.id.startsWith('ELEC') || selObject.id.startsWith('WAR');
    var availDiffYear;
    if (this.state.diffYear) {
      if (selObject.range.includes(this.state.diffYear.toString())) {
        availDiffYear = this.state.diffYear;
      } else {
        availDiffYear = selObject.range[selObject.range.length - 1];
      }
    } else {
      availDiffYear = this.state.diffYear;
    }
    var dfYear = singleYear ? '' : availDiffYear;
    this.setState({
      selectedTopicId: objId,
      selectedTopicObject: selObject,
      selectedYear: year,
      diffYear: dfYear
    });
  }

  handleOverlay(objId) {
    var selObject = objId ? this.state.topics.find(x => x.id === objId) : [];
    var year;
    var isEconomy = selObject.segment === 'Economy';
    if (objId) {
      if (selObject.range.includes(this.state.overlayYear)) {
        year = this.state.overlayYear;
        // eslint-disable-next-line
      } else if (selObject.range[0] == 1964) {
        year = 1970;
      } else {
        year = selObject.range[0];
      }
    }
    this.setState({
      overlayTopicId: objId,
      overlayTopicObject: selObject,
      overlayYear: year,
      diffYear: '',
      dualSliders: isEconomy
    });
  }

  handleYear(yr) {
    if (Array.isArray(yr)) {
      if (yr[0] != yr[1]) {
        yr.sort();
        this.setState({
          selectedYear: yr[0],
          diffYear: yr[1]
        });
      }
    } else {
      var year = yr === 1964 ? 1970 : yr;
      this.setState({
        selectedYear: year
      });
    }
  }

  handleOverlayYear(yr) {
    var year = yr === 1964 ? 1970 : yr;
    this.setState({
      overlayYear: year
    });
  }

  enableDiff() {
    var startYear = this.state.selectedTopicObject.range[0];
    if (this.state.diffYear) {
      var endYear = '';
    } else {
      var endYear = this.state.selectedTopicObject.range.slice(-1)[0];
    }
    this.setState({
      selectedYear: startYear,
      diffYear: endYear
    });
  }

  render() {
    var panelTop = this.state.overlayTopicId ? '10rem' : '6.5rem';
    var buttonTop = this.state.overlayTopicId ? '11rem' : '7rem';
    var singleYear =
      this.state.selectedTopicId.startsWith('ELEC') ||
      this.state.selectedTopicId.startsWith('WAR');

    return (
      <div>
        {this.state.overlayTopicObject.desc ? (
          <TopicBar
            topic={this.state.overlayTopicObject}
            year={this.state.overlayYear}
            handler={this.handleOverlayYear}
            overlay={true}
            dual={this.state.dualSliders}
          />
        ) : (
          ''
        )}
        <TopicBar
          topic={this.state.selectedTopicObject}
          year={this.state.selectedYear}
          handler={this.handleYear}
          overlay={false}
          diffYear={this.state.diffYear}
          dual={this.state.dualSliders}
        />
        <div className="panel" style={{ marginTop: panelTop }}>
          <TopicTitle
            colors={this.state.colors}
            diffColors={this.state.diffColors}
            topic={this.state.selectedTopicObject}
            overlayTopic={this.state.overlayTopicObject}
            diffYear={this.state.diffYear}
            year={this.state.selectedYear}
          />
          <Accordion defaultActiveKey="0" id="TopicList">
            {this.buildList(segments, topics)}
          </Accordion>
        </div>
        <BaseMap
          topicId={this.state.selectedTopicId}
          topic={this.state.selectedTopicObject}
          overlayTopicId={this.state.overlayTopicId}
          overlayTopic={this.state.overlayTopicObject}
          year={this.state.selectedYear}
          diffYear={this.state.diffYear}
          overlayYear={this.state.overlayYear}
          colors={this.state.colors}
          diffColors={this.state.diffColors}
          allYears={this.state.allYears}
        />
        <Form.Check
          type="switch"
          id="diff-switch"
          label="Compare years"
          style={{ position: 'absolute', top: buttonTop, right: '18rem' }}
          disabled={this.state.overlayTopicId || singleYear}
          checked={this.state.diffYear && !singleYear}
          onChange={this.enableDiff}
          title="Display the change in selected topic between two years. Available for themes with multiple registered years and outside of the overlay mode."
        />
        <OverlaySelect
          baseTopic={this.state.selectedTopicId}
          overlayTopic={this.state.overlayTopicId}
          year={this.state.selectedYear}
          topics={this.state.topics}
          handler={this.handleOverlay}
        />
        <div
          className="mapboxgl-ctrl-bottom-right"
          style={{
            marginBottom: '30px',
            marginRight: '10px',
            align: 'right',
            zIndex: '10000',
            pointerEvents: 'auto'
          }}
        >
          <a href="https://wwwen.uni.lu/">
            <Image src="./assets/uni-logo.png" width="40" height="40" />
          </a>
          <br />
          <Image src="./assets/erc-logo.jpg" width="40" height="40" />
          <br />
          <Image src="./assets/eu-flag.jpg" width="40" height="25" />
        </div>
      </div>
    );
  }
}

export default TopicList;
