import React from 'react';
import Card from 'react-bootstrap/Card';
import MapLegend from '../components/MapLegend.js';

class TopicTitle extends React.Component {
  render() {
    var stops = this.props.diffYear
      ? this.props.topic.diffStops
      : this.props.topic.stops;
    var colors = this.props.diffYear
      ? this.props.diffColors
      : this.props.colors;
    var diffDesc = this.props.diffYear
      ? 'Difference between years ' +
        this.props.year +
        ' and ' +
        this.props.diffYear
      : '';
    var desc;
    if (Array.isArray(this.props.topic)) {
      if (this.props.topic.length > 1) {
        var descarray = [];
        this.props.topic.forEach(e => {
          if (e.desc != 'All WWII deaths') descarray.push(e.desc);
        });
        desc = descarray.join(', ');
        if (this.props.topic[0].desc === this.props.topic[1].desc) {
          desc = 'All WWII deaths';
        }
      } else {
        desc = this.props.topic[0].desc;
      }
      // no varying stops if circles
      stops = [];
    } else {
      desc = this.props.topic.desc;
    }

    return (
      <Card id="TopicTitle" className="shadow-sm">
        <Card.Body>
          <Card.Subtitle className="mb-2 text-muted">
            {desc + ' ' + diffDesc}
          </Card.Subtitle>
          <MapLegend colors={colors} stops={stops} />
          {this.props.overlayTopic.desc ? (
            <>
              <br />
              <Card.Subtitle className="mb-2 text-muted">
                {this.props.overlayTopic.desc}
              </Card.Subtitle>
              <MapLegend
                colors={this.props.colors}
                overlay={true}
                stops={this.props.overlayTopic.stops}
              />
            </>
          ) : (
            ''
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default TopicTitle;
