import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapTooltip from '../components/MapTooltip.js';
import PrintSelect from '../components/PrintSelect.js';

// import FeatureAlert from '../components/FeatureAlert.js'
//
import Button from 'react-bootstrap/Button';
import mbconfig from '../enums/mbconfig_deaths.js';

mapboxgl.accessToken = mbconfig.token;

class BaseMap extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      lng: mbconfig.mapCenterLng,
      lat: mbconfig.mapCenterLat,
      zoom: mbconfig.mapZoom
    };
    var hoveredStateId = null;
    var clickedStateId = null;
  }

  componentDidMount() {
    const { lng, lat, zoom } = this.state;

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: mbconfig.style,
      center: [lng, lat],
      maxZoom: mbconfig.maxZoom,
      minZoom: mbconfig.minZoom,
      preserveDrawingBuffer: true,
      zoom
    });

    // disable map rotation using right click + drag
    this.map.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();

    this.map.on('style.load', () => {
      this.setCircles();
    });

    this.map.on('move', () => {
      const { lng, lat } = this.map.getCenter();

      this.setState({
        lng: lng.toFixed(4),
        lat: lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2)
      });
    });

    // add scale and zoom controls
    var scale = new mapboxgl.ScaleControl({
      position: 'bottom-right',
      maxWidth: 120,
      unit: 'metric'
    });
    this.map.addControl(scale);
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

    this.tooltipContainer = document.createElement('div');
    const tooltip = new mapboxgl.Marker(this.tooltipContainer, {
      offset: [0, -30]
    })
      .setLngLat([0, 0])
      .addTo(this.map);

    this.map.on('mousemove', e => {
      // registration bounding box around cursor
      var width = 10;
      var height = 10;
      // TODO omit transparent circles from tooltip select
      var features = this.map.queryRenderedFeatures(
        [
          [e.point.x - width / 2, e.point.y - height / 2],
          [e.point.x + width / 2, e.point.y + height / 2]
        ],
        {
          layers: [
            'wwiihrv',
            'wwiihrvyears',
            'wwiibih',
            'wwiibihyears',
            'wwiimkd',
            'wwiimkdyears',
            'wwiisvn',
            'wwiisvnyears',
            'wwiisrbxkxmnevojyears',
            'wwiisrbxkxmnevoj'
          ]
        }
      );
      tooltip.setLngLat(e.lngLat);
      this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      this.setTooltip(features);
    });
  }

  componentDidUpdate() {
    if (this.map.isStyleLoaded()) {
      this.setCircles();
    }
  }

  componentWillUnmount() {
    this.map.remove();
  }

  clearLayers() {
    let layers = [
      'wwiihrv',
      'wwiihrvyears',
      'wwiibih',
      'wwiibihyears',
      'wwiimkd',
      'wwiimkdyears',
      'wwiisvn',
      'wwiisvnyears',
      'wwiisrbxkxmnevojyears',
      'wwiisrbxkxmnevoj'
    ];
    layers.forEach(e => {
      this.map.setLayoutProperty(e, 'visibility', 'none');
    });
  }

  setCircles() {
    let regularLayers = [
      'wwiihrv',
      'wwiibih',
      'wwiimkd',
      'wwiisvn',
      'wwiisrbxkxmnevoj'
    ];
    let yearsLayers = [
      'wwiihrvyears',
      'wwiibihyears',
      'wwiimkdyears',
      'wwiisvnyears',
      'wwiisrbxkxmnevojyears'
    ];

    // layers are already uploaded in style 'deaths' in MB Studio
    let layers = this.props.year === '1940' ? regularLayers : yearsLayers;

    // create the filter expression (sum)
    var expression = ['+'];
    this.props.combos.forEach(e => {
      expression.push(['case', ['has', e], ['get', e], 0]);
    });
    this.clearLayers();
    console.log(this.props.year);
    let yearFilter =
      this.props.year === '1940'
        ? 0.45
        : ['match', ['get', 'death_yr'], parseInt(this.props.year), 0.45, 0];
    layers.forEach(layer => {
      this.map.setLayoutProperty(layer, 'visibility', 'visible');
      this.map.setPaintProperty(layer, 'circle-color', '#f77');
      this.map.setPaintProperty(layer, 'circle-opacity', yearFilter);
      this.map.setPaintProperty(
        layer,
        'circle-radius',
        [
          'interpolate',
          ['linear'],
          ['zoom'],
          4.8,
          ['interpolate', ['linear'], expression, 0, 0, 10, 1.4, 1000, 7],
          5.8,
          ['interpolate', ['linear'], expression, 0, 0, 10, 1.8, 1000, 8],
          6.8,
          ['interpolate', ['linear'], expression, 0, 0, 10, 2, 1000, 15],
          7.8,
          ['interpolate', ['linear'], expression, 0, 0, 10, 3, 1000, 18],
          8.8,
          ['interpolate', ['linear'], expression, 0, 0, 10, 3, 1000, 23]
        ],
        0
      );
    });
  }

  tooltipContainer;

  setTooltip(features) {
    if (features.length) {
      ReactDOM.render(
        React.createElement(MapTooltip, {
          features: features,
          titleCol: 'true_location',
          topicId: this.props.topicId
        }),
        this.tooltipContainer
      );
    } else {
      ReactDOM.unmountComponentAtNode(this.tooltipContainer);
    }
  }

  getCanvas() {
    // output quality per device
    /*
    Object.defineProperty(window, 'devicePixelRatio', {
        get: function() {return 600 / 96} // 400 dpi
    });
    */
    var imgHref = this.map.getCanvas().toDataURL('image/png');
    return imgHref;
  }

  render() {
    var mtop = '95px';
    var hght = '90%';
    const style = {
      width: '100%',
      height: hght,
      position: 'absolute',
      marginTop: mtop
    };
    var descs = this.props.selectedTopics.map(e => e['desc']);
    if (
      this.props.selectedTopics[0].desc == this.props.selectedTopics[1].desc
    ) {
      descs = ['All WWII deaths'];
    }
    var printObject = {
      name: this.props.genericTopic.name,
      stops: [],
      desc: descs.join(', ')
    };
    return (
      <>
        <div style={style} ref={el => (this.mapContainer = el)} />
        <PrintSelect
          topic={printObject}
          overlayTopic={''}
          diffYear={''}
          year={this.props.year}
          overlayYear={this.props.overlayYear}
          apiToken={mbconfig.token}
          mapStyle={mbconfig.style.substring()}
          colors={this.props.colors}
          lat={this.state.lat}
          lng={this.state.lng}
          zoom={this.state.zoom}
          canvasUrl={() => this.getCanvas()}
          circles={1}
        />
      </>
    );
  }
}

export default BaseMap;
