import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class MapLegend extends Component {
  buildLegend() {
    if (this.props.overlay) {
      return (
        <Row>
          <Image
            style={{ width: '260px', height: '15px' }}
            src="assets/legend.png"
          />
        </Row>
      );
    } else {
      if (this.props.colors.length == 1) {
        return (
          <>
            <Row>
              <svg height={30}>
                <circle
                  cx={10}
                  cy={15}
                  r={3}
                  fill={this.props.colors[0]}
                  fill-opacity={0.45}
                />
                <circle
                  cx={55}
                  cy={15}
                  r={6}
                  fill={this.props.colors[0]}
                  fill-opacity={0.45}
                />
                <circle
                  cx={108}
                  cy={15}
                  r={15}
                  fill={this.props.colors[0]}
                  fill-opacity={0.45}
                />
              </svg>
            </Row>
          </>
        );
      } else {
        return (
          <Row>
            {this.props.colors.map((e, i) => (
              <Col
                xs
                key={'legColor-' + i.toString()}
                style={{ background: e, height: '15px' }}
              >
                {' '}
                &nbsp;{' '}
              </Col>
            ))}
          </Row>
        );
      }
    }
  }

  buildStops() {
    var stops = this.props.stops;
    if (this.props.colors.length == 1) {
      return (
        <Row>
          <Col xs={2} style={{ fontSize: '10px', padding: '6px' }}>
            10
          </Col>
          <Col xs={2} style={{ fontSize: '10px', padding: '6px' }}>
            500
          </Col>
          <Col
            xs={3}
            style={{ fontSize: '10px', padding: '6px', paddingLeft: '10px' }}
          >
            1000
          </Col>
        </Row>
      );
    } else {
      return (
        <Row style={{ marginLeft: '16%' }}>
          {this.props.colors.map((e, i) =>
            // last element not needed in legend
            stops[i] !== stops.slice(-1)[0] ? (
              <Col
                key={'stop-' + i.toString()}
                xs={3}
                style={{ fontSize: '10px', padding: 0, width: '20%' }}
              >
                {' '}
                {stops[i]}{' '}
              </Col>
            ) : (
              ''
            )
          )}
        </Row>
      );
    }
  }

  render() {
    return (
      <Container id="LegendStripe">
        {this.buildLegend()}
        {this.buildStops()}
      </Container>
    );
  }
}

export default MapLegend;
